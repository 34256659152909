window.sr = ScrollReveal();


var countUpOptions = {  
    useEasing: false,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    suffix: '%'
};

/** section 0
 * - intro
 */
sr.reveal('.fade-in-and-up-a', {
    // Time in milliseconds.
    duration: 1000,
    delay: 100,
    scale: 1,
    beforeReveal: function(domEl) {}
});

sr.reveal('.fade-in-and-up-b', {
    // Time in milliseconds.
    duration: 1000,
    delay: 500,
    scale: 1,
    beforeReveal: function(domEl) {}
});


/** section 1 
 * - Organizations rebrand to tell a new story
 */
sr.reveal('.fade-in-and-up-c', {
    duration: 1000,
    delay: 1500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

sr.reveal('#countup-75-1', {
    distance: 0,
    duration: 500,
    delay: 0,
    scale: 0,
    afterReveal: function(domEl) {
        setTimeout(function() {
            var numAnim1 = new CountUp('countup-75-1', 0, '75', 0, 2, countUpOptions);
            var numAnim2 = new CountUp('countup-75-2', 0, '75', 0, 2, countUpOptions);
            var numAnim3 = new CountUp('countup-63-1', 0, '63', 0, 2, countUpOptions);
            var numAnim4 = new CountUp('countup-38-1', 0, '38', 0, 2, countUpOptions);
            var numAnim5 = new CountUp('countup-38-2', 0, '38', 0, 2, countUpOptions);

            if (!numAnim1.error) {
                numAnim1.start();
                numAnim2.start();
                numAnim3.start();
                numAnim4.start();
                numAnim5.start();
            } else {
                console.error(numAnim1.error);
                console.error(numAnim2.error);
            }
        }, 550);
    }
});


/** section 2 
 * - Rebranding attracts new customers
 */

sr.reveal('#attract-cutomers-1', {
    // Time in milliseconds.
    duration: 1000,
    delay: 500,
    beforeReveal: function(domEl) {
        console.log('reveal attract customer step 1 ');
    }
});

sr.reveal('#attract-cutomers-2', {
    // Time in milliseconds.
    duration: 1000,
    delay: 900,
    beforeReveal: function(domEl) {
        console.log('reveal attract customer step 2');
    }
});

sr.reveal('#attract-cutomers-3', {
    // Time in milliseconds.
    duration: 1000,
    delay: 1400,
    beforeReveal: function(domEl) {
        console.log('reveal attract customer step 3');
    }
});

sr.reveal('#attract-cutomers-4', {
    // Time in milliseconds.
    duration: 1000,
    delay: 1700,
    beforeReveal: function(domEl) {
        console.log('reveal attract customer step 4');
    }
});

sr.reveal('#attract-cutomers-5', {
    // Time in milliseconds.
    duration: 1000,
    delay: 1900,
    beforeReveal: function(domEl) {
        console.log('reveal attract customer step 5');
    }
});

/** book and text */
sr.reveal('.fade-in-and-up-9,.fade-in-and-up-10', {
    // Time in milliseconds.
    duration: 1000,
    delay: 1700,
    beforeReveal: function(domEl) {}
});

/** section 3 
 * - A new logo, brand colors, and website receive the most attention from clients
 */

sr.reveal('.animation2', {
    distance: 0,
    duration: 0,
    delay: 1500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {

        runAnimation('animation1');
        runAnimation('animation2');
        runAnimation('animation3');
        runAnimation('animation4');
        runAnimation('animation5');
        runAnimation('animation6');

    }
});

/** section 4 
 * - Rebranding projects are not always comprehensive
 */

sr.reveal('.--animation-not-ran-1', {
    distance: 0,
    duration: 0,
    delay: 000,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {
        setTimeout(function() {
            $('.--animation-not-ran-1').addClass("animate --animation-ran").removeClass('--animation-not-ran-1');
        }, 750);
    }
});

/**
 * section 5
 * Most organizations keep their name when they rebrand
 */

sr.reveal('.fade-in-and-up-1', {
    duration: 1000,
    delay: 500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

sr.reveal('.fade-in-and-up-2', {
    duration: 1200,
    delay: 1000,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

sr.reveal('.fade-in-and-up-3', {
    duration: 1200,
    delay: 1150,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

sr.reveal('.fade-in-and-up-4', {
    duration: 1200,
    delay: 1300,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

/**
 * section 6
 * Rebranding impacts organizational culture
 */
sr.reveal('.fade-in-and-up-5', {
    duration: 1200,
    delay: 300,
    beforeReveal: function(domEl) {
        //$(this).addClass('fadeInUp');
    },
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});
sr.reveal('.fade-in-and-up-6', {
    duration: 1200,
    delay: 600,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});
sr.reveal('.fade-in-and-up-7', {
    duration: 1200,
    delay: 900,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});
sr.reveal('.fade-in-and-up-8', {
    duration: 1200,
    delay: 1200,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
});

/** section 9?? 
 * - Most organizations conduct a brand relaunch campaign
 */

sr.reveal('.fade-in-and-up-boxes', {
    scale: 1,
    duration: 1000,
    delay: 750,
    beforeReveal: function(domEl) {
        //$(this).addClass('fadeInUp');
    },
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}

}, 190);

/** section 8?? 
 * - Rebranding attracts stakeholders
 */

// 38%
sr.reveal('.row-7 .block', {
    distance: 0,
    duration: 0,
    delay: 1000,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-8 .block', {
    distance: 0,
    duration: 0,
    delay: 750,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-9 .block', {
    distance: 0,
    duration: 0,
    delay: 500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-10 .block', {
    distance: 0,
    duration: 0,
    delay: 250,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

// 84% 
sr.reveal('.row-2-1 .block', {
    distance: 0,
    duration: 0,
    delay: 2250,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-3-1 .block', {
    distance: 0,
    duration: 0,
    delay: 2000,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-4-1 .block', {
    distance: 0,
    duration: 0,
    delay: 1750,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-5-1 .block', {
    distance: 0,
    duration: 0,
    delay: 1500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-6-1 .block', {
    distance: 0,
    duration: 0,
    delay: 1250,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-7-1 .block', {
    distance: 0,
    duration: 0,
    delay: 1000,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-8-1 .block', {
    distance: 0,
    duration: 0,
    delay: 750,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-9-1 .block', {
    distance: 0,
    duration: 0,
    delay: 500,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);

sr.reveal('.row-10-1 .block', {
    distance: 0,
    duration: 0,
    delay: 250,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {}
}, 100);



sr.reveal('#countup-84-1', {
    distance: 0,
    duration: 0,
    delay: 0,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {
        // var numAnim16 = new CountUp(domEl, 0, '84', 0, 2, countUpOptions);
        // if (!numAnim16.error) {
        //     numAnim16.start();
        // }
    }
});


/** section 9?? 
 * - Participants were satisfied with the decision to rebrand
 */
sr.reveal('.people-icons', {
    distance: 0,
    duration: 0,
    delay: 0,
    beforeReveal: function(domEl) {},
    // Callbacks that fire for each completed element reveal, and reset.
    afterReveal: function(domEl) {
        $('.people-icons').addClass('animate');
    }
});



/**
 *
 * GLOBAL methods
 * 
 */

function runAnimation(selector) {
    $('.' + selector).removeClass('animate');
}

function setChildrenCssValues(selector, childSelector, property, value) {

    selector.children(childSelector).each(function(index) {
        $(this).css(property, value);
    });

}